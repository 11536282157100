import { getField, updateField } from 'vuex-map-fields';
import { metaArrayToObject } from '@/utilities';

function defaults(o = {}) {
  return Object.assign(
    {
      name: '',
      type: '',
    },
    o
  );
}

export const state = () => ({
  id: null,
  fields: defaults(),
});

export const getters = {
  id: (state) => state.id,
  fields: (state) => state.fields,
  getField,
};

export const mutations = {
  id(state, value) {
    state.id = value;
  },
  fields(state, value) {
    state.fields = value;
  },
  updateField,
};

export const actions = {
  addPet({ commit, dispatch }) {
    commit('id', null);
    commit('fields', defaults());
    dispatch('application/ui/showPetPanel', null, { root: true });
  },
  editPet({ commit, dispatch }, pet) {
    commit('id', pet.id);
    commit('fields', defaults(metaArrayToObject(pet.meta)));
    dispatch('application/ui/showPetPanel', null, { root: true });
  },
};
