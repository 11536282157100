import cookies from 'js-cookie';
import {
  getBaseUrl,
  isAdminOrHigher,
  hexToRgb,
  saveAnonymousProfile,
  getAnonymousProfile,
} from '@/utilities';
import { removeLegacyCookie } from '@/modules/cookies';
import {
  sanitizeLogInRedirect,
  sanitizeSignUpRedirect,
} from '@/utilities/allowed-redirects';

import LOGIN_USER from '~/graphql/mutations/LoginUser';
import GET_PARTNERSHIP_BRANDING from '@/graphql/queries/GetPartnershipBranding';
import GET_WILL_QUERY from '~/graphql/queries/GetWill';
import GET_PRODUCTS_PURCHASED_INIT_QUERY from '~/graphql/queries/GetProductsPurchasedInit';
import GET_PARTNERSHIP_APP_DATA from '@/graphql/queries/GetPartnershipAppData';
import GET_PARTNERSHIP_AFFILIATE_FEATURES from '@/graphql/queries/GetPartnershipAffiliateFeatures';

const ADMIN_DASHBOARD_PATH = '/sw/admin';

export default async function ({
  app,
  $auth,
  store: { state, commit, dispatch, getters },
  $ff,
}) {
  const client = app.apolloProvider.defaultClient;
  const currentPath = window.location.pathname;
  const currentPathElements = currentPath.split('/');
  let affiliateFromUrl = currentPathElements[1];
  if (app.i18n.localeCodes.includes(affiliateFromUrl)) {
    affiliateFromUrl = currentPathElements[2];
  }
  const cookie = cookies.get('affiliate_slug');

  let affiliateSlug;
  if (currentPathElements.includes('get-started')) {
    affiliateSlug = affiliateFromUrl || cookie;
  } else {
    affiliateSlug = cookie;
  }

  if (affiliateSlug) {
    const { data: brandingData } = await client.query({
      query: GET_PARTNERSHIP_BRANDING,
      variables: {
        slug: affiliateSlug,
      },
    });

    const root = document.documentElement;
    if (brandingData.getPartnershipBranding) {
      const {
        logo,
        branding,
        getStartedTitleOne,
        getStartedDescriptionOne,
        getStartedTitleTwo,
        getStartedDescriptionTwo,
        getStartedTitleThree,
        getStartedDescriptionThree,
        getStartedAffiliateLogoShown,
        appHeaderAffiliateLogoShown,
      } = brandingData.getPartnershipBranding;

      dispatch('application/affiliate/setSlug', affiliateSlug);
      cookies.set('affiliate_slug', affiliateSlug);
      saveAnonymousProfile({
        affiliateSlug,
      });

      dispatch('application/affiliate/setGetStartedBranding', {
        titleOne: getStartedTitleOne,
        descriptionOne: getStartedDescriptionOne,
        titleTwo: getStartedTitleTwo,
        descriptionTwo: getStartedDescriptionTwo,
        titleThree: getStartedTitleThree,
        descriptionThree: getStartedDescriptionThree,
        affiliateLogoVisible: getStartedAffiliateLogoShown,
      });

      dispatch('application/affiliate/setAppBranding', {
        affiliateLogoVisible: appHeaderAffiliateLogoShown,
      });

      const { theme, darker, lighter, landingImage } = branding;

      dispatch('application/affiliate/setBranding', {
        logo,
        theme,
        darker,
        lighter,
        landingImage,
      });

      root.classList.add('--affiliate');
      if (theme) {
        root.style.setProperty('--theme', hexToRgb(theme));
      }
      if (darker) {
        root.style.setProperty('--darker', hexToRgb(darker));
      }
      if (lighter) {
        root.style.setProperty('--lighter', hexToRgb(lighter));
      }
    } else {
      root.classList.remove('--affiliate');
      affiliateSlug = 'sw';
    }
  }

  if ($auth.loggedIn) {
    const token = $auth.strategy.token.get() || '';

    await app.$apolloHelpers.onLogin(token.split(' ')[1]);

    try {
      const { data } = await client.mutate({ mutation: LOGIN_USER });
      const user = data.loginUser.user;

      if (user) {
        if (window.analytics && data.loginUser.action === 'login') {
          window.analytics?.track('login', {
            identity_provider: data.loginUser.identityProvider,
          });
        }
        commit('setEmail', user.email);
        commit('setRole', user.role);
        commit('setUserId', user.id);
        commit('setVerified', user.verified);
        commit('setToken', token);
        commit('setAffiliateUserId', user.affiliate_user_id);
        commit(
          'cart/setSubscriptionAutoRenewal',
          $ff.freeCartSubscriptionAutoSelected()
        );

        await dispatch('directory-person/getDirectoryPersonsByOwnerId', {
          ownerId: user.id,
          personId: user?.person_id,
        });
        await dispatch('tool/getTools', {
          ownerId: user.id,
        });

        const willHelpAssessmentTool = getters['tool/willHelpAssessmentTool'];
        if (willHelpAssessmentTool) {
          dispatch('vault-item/getWillHelpAssessmentVaultItems', {
            ids: willHelpAssessmentTool.vaultItemIds,
          });
        }

        if (data.loginUser.action === 'sign_up') {
          await dispatch('external-identifiers/createAnalyticIdentifiers');
        } else {
          await dispatch('external-identifiers/getAnalyticIdentifiers');
        }
        await dispatch('product/getProducts');
      } else {
        removeLegacyCookie();
        app.$apolloHelpers.onLogout();
        dispatch('resetUserState');
        dispatch('resetCheckoutState');
        dispatch('resetUI');
        await $auth.logout();
      }
    } catch (e) {
      console.error(e.message);
      removeLegacyCookie();
      app.$apolloHelpers.onLogout();
      dispatch('resetUserState');
      dispatch('resetCheckoutState');
      dispatch('resetUI');
      await $auth.logout();
    }

    try {
      let redirectPath;

      if (isAdminOrHigher(state.role)) {
        if (!window.location.pathname.includes(ADMIN_DASHBOARD_PATH)) {
          redirectPath = ADMIN_DASHBOARD_PATH;
        }
      } else {
        const { data } = await client.query({ query: GET_WILL_QUERY });
        if (data.getWill.will) {
          dispatch('setWillId', data.getWill.will.id);
          dispatch('setWillStatus', data.getWill.will.status);
          dispatch('will/setData', data.getWill.will);
          dispatch('will/getAllWillRelatedData');
        }

        const {
          data: { poa },
        } = await client.query({
          query: GET_PRODUCTS_PURCHASED_INIT_QUERY,
        });
        if (poa) {
          dispatch('poa/setPoaId', poa.id);
          dispatch('poa/getAllPoaRelatedData');
        }

        await dispatch('coupon/getCouponLatestReferral');
        await dispatch('charity-causes/getCauses');
        await dispatch('orders/getOrders');
        await dispatch('flag/getPlanFlags', { planId: data.getWill.will.id });
        await dispatch('cart/getCartItems');
        await dispatch('product-prices/getProductPrices');

        const signUpRedirectCookie = cookies.get('signup_redirect');
        const redirectCookie = cookies.get('redirect');

        if (redirectCookie) {
          redirectPath = sanitizeLogInRedirect(redirectCookie);
        } else if (signUpRedirectCookie) {
          redirectPath = sanitizeSignUpRedirect(signUpRedirectCookie);
        }

        cookies.remove('signup_redirect');
        cookies.remove('redirect');

        const { data: partnershipAppData } = await client.query({
          query: GET_PARTNERSHIP_APP_DATA,
        });
        dispatch('setCharitySource', partnershipAppData.getPartnershipAppData);

        if (affiliateSlug) {
          const { data: affiliateData } = await client.query({
            query: GET_PARTNERSHIP_AFFILIATE_FEATURES,
            variables: {
              slug: affiliateSlug,
            },
          });

          if (affiliateData.getPartnershipAffiliateFeatures) {
            const {
              dashboardEnabled,
              willEnabled,
              powerOfAttorneyEnabled,
              endOfLifeEnabled,
              giftEnabled,
              onboardingNeedsAssessmentEnabled,
              willTierTwoEnabled,
              affiliateUserIdEnabled,
              affiliateUserIdFieldName,
              couponCodeEnabled,
              couponCodeFieldName,
            } = affiliateData.getPartnershipAffiliateFeatures;

            dispatch('application/affiliate/setFeatures', {
              dashboardEnabled,
              willEnabled,
              powerOfAttorneyEnabled,
              endOfLifeEnabled,
              giftEnabled,
              onboardingNeedsAssessmentEnabled,
              willTierTwoEnabled,
              affiliateUserIdEnabled,
              affiliateUserIdFieldName,
              couponCodeEnabled,
              couponCodeFieldName,
            });
          }
        }

        const hasUtm = cookies.get('has_utm');
        const utm = getAnonymousProfile()?.utm || {};
        const utmParams = new URLSearchParams(utm).toString();
        cookies.remove('has_utm');

        if (hasUtm && !window.location.search.includes(utmParams)) {
          window.location.search = `?${utmParams}`;
        }
      }

      if (redirectPath && window.location.pathname !== redirectPath) {
        window.location.href = getBaseUrl() + redirectPath;
      }
    } catch (e) {
      console.error(e.message);
    }
  }
}
